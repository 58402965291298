$(function(){
    const modal = $('.js-story-modal')
    const modalImageElement =  modal.find('.js-story-image-mob')

    function openStoryModal(btn) {
        const contentId = Number(btn.attr('data-id'))
        const story = stories[contentId - 1]
        const tempImagePath = btn.closest('.help-card').find('.help-card__image-wrapper img').attr('src')
        
        modal.find('.js-story-title').html(story.name)
        modal.find('.js-story-region').html(story.region)
        modal.find('.js-story-text').html(story.text)
        modalImageElement.attr('src', tempImagePath).addClass('blurred')

        const modalImage = document.createElement('img')
        const removeImageLoader = () => {
            modalImageElement.attr('src', story.imageMobUrl)
            modalImageElement.siblings('source').attr('srcset', story.imageUrl)
            modalImageElement.removeClass('blurred')
            
            modalImage.removeEventListener('load', removeImageLoader)
        }
        modalImage.addEventListener('load', removeImageLoader)
        modalImage.src = window.innerWidth < 768 ? story.imageMobUrl : story.imageUrl

        $('body').addClass('no-overflow')
        modal.addClass('is-active')
        
        document.querySelector('.popup__inner').scrollTop = 0
    }
    function closeModal() {
        modal.removeClass('is-active')
        $('body').removeClass('no-overflow')
    }

    $('.js-open-story-modal').on('click', function(){
        openStoryModal( $(this) )
    })
    $('.js-modal-close').on('click', function(){
        closeModal()
    })
    $(document).on('keyup', function(e) {
        if (e.code == 'Escape' && $('.js-story-modal.is-active').length) {
            closeModal()
        }
    })

    $('.js-scroll-to').on('click', function(e){
        e.preventDefault();
        const headerHeight = $('.header').outerHeight()
        const target = $(this).attr('data-target')
        const targetElement = $(`#${target}`)
        pageAutoScroll(targetElement.offset().top - headerHeight, 400)
    })
})